<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <img :src="importedImg2" alt="" class="imgItem2" @click="gotoBot" />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
// import importedImg1 from "@/page/mitrsinduan/components/img/footer.png";
import importedImg1 from "@/page/mitrsinduan/components/img/footer_1.png";
import importedImg2 from "@/page/mitrsinduan/components/img/footer_2.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2, 
      altText: "图片",
    };
  },
  methods:{
    gotoBot(){
      if(this.$router.history.current.path !== '/bot'){
        this.$router.push('/bot');
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

/* .imgItem1, */
/* .imgItem2, */
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box{
  background-image: url("@/page/mitrsinduan/components/img/footer.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 450px;
  /* position: relative; */
}

.imgItem1{
  width: 14.5%;
  /* margin: 110px 1170px; */
  margin-top: 110px;
  margin-left: 72%;
}

.imgItem2{
  width: 14.5%;
  /* margin: 110px 1170px; */
  margin-top: 30px;
  margin-left: 72%;
}
</style>
