<template>
    <div class="imgBox min-w-1400">
      <div class="imgBox1 min-w-1400">
          <div class="img1Box max-w-1400">
            <img :src="importedImg1" alt="" class="imgItem1" />
          </div>
      </div>
      <div class="imgBox2 min-w-1400">
          <div class="img2Box max-w-1400">
            <img :src="importedImg2" alt="" class="imgItem2" @click="gotoHref"/>
          </div>
      </div>
      <div class="imgBox3 min-w-1400">
          <div class="img3Box max-w-1400">
            <img :src="importedImg3" alt="" class="imgItem3" />
          </div>
      </div>
      <div class="imgBox4 min-w-1400">
          <div class="img4Box max-w-1400">
            <img :src="importedImg4" alt="" class="imgItem4" />
          </div>
      </div>
      <div class="imgBox5 min-w-1400">
          <div class="img5Box max-w-1400">
            <!-- <img :src="importedImg5" alt="" class="imgItem5" /> -->
          </div>
      </div>
      <div class="imgBox6 min-w-1400">
          <div class="img6Box max-w-1400">
            <!-- <img :src="importedImg6" alt="" class="imgItem6" /> -->
          </div>
      </div>
      <!-- <div class="imgBox7 min-w-1400">
          <div class="img7Box max-w-1400">
            <img :src="importedImg7" alt="" class="imgItem7" />
          </div>
      </div> -->
    </div>
  </template>
  
  <script>
  import "./index.css";
  import importedImg1 from "@/page/mitrsinduan/components/img/5_1.png";
  import importedImg2 from "@/page/mitrsinduan/components/img/5_2.png";
  import importedImg3 from "@/page/mitrsinduan/components/img/5_3.png";
  import importedImg4 from "@/page/mitrsinduan/components/img/5_4.png";
//   import importedImg5 from "@/page/mitrsinduan/components/img/5_5.png";
//   import importedImg6 from "@/page/mitrsinduan/components/img/5_6.png";
  // import importedImg7 from "@/page/mitrsinduan/components/img/5_7.png";
  
  export default {
    data() {
      return {
        importedImg1: importedImg1,
        importedImg2: importedImg2,
        importedImg3: importedImg3,
        importedImg4: importedImg4,
        // importedImg5: importedImg5,
        // importedImg6: importedImg6,
        // importedImg7: importedImg7,
        altText: "图片",
      };
    },
    created() {
      this.gotoTop();
    },
    methods: {
      gotoTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      gotoHref(){
        window.open('https://app.bot.or.th/BOTLicenseCheck/License/ViewDetail?id=285', '_blank');
      }
    },
  };
  </script>
  
  <style scoped>
  .imgBox {
    display: flex;
    flex-direction: column;
    background-color: white;
  }
  img {
    display: block;
  }
  .imgBox1,
  .imgBox2,
  .imgBox3,
  .imgBox4,
  .imgBox5,
  .imgBox6,
  .imgBox7 {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: "";
  }
  
  .img1Box,
  .img2Box,
  .img3Box,
  .img4Box,
  .img5Box,
  .img6Box,
  .img7Box {
    margin: 0;
    overflow: hidden;
    width: 100%;
    background-color: "";
  }
  
  .imgItem1,
  .imgItem2,
  .imgItem3,
  .imgItem4,
  .imgItem5,
  .imgItem6,
  .imgItem7 {
    width: 100%;
    margin: 0;
  }
  
 .img1Box{
    margin-top: 40px;
 }
 .img4Box{
    margin-top: 50px;
    margin-bottom: 70px;
 }
 .img1Box,
 .img2Box,
 .img3Box,
 .img4Box{
    padding: 0 120px;
 }
  </style>  